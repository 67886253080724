import React, { useState, ChangeEvent, ReactNode } from "react";
import clsx from "clsx";

type InputProps = {
  type?: string; // e.g., "text", "password", "email"
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; // Add onKeyDown prop
  state?: "Default" | "Hover" | "Focused";
  size?: "Small" | "Medium" | "Large";
  disabled?: boolean;
  theme?: "light" | "dark";
  className?: string;
  icon?: ReactNode; // Icon component or element
  iconPosition?: "left" | "right"; // Position of the icon
};

const stateClasses = {
  light: {
    Default: "border-gray-300 bg-white text-black",
    Hover: "border-[#3E67A3] bg-white text-black",
    Focused: "border-[#0F428D] border-[2px] bg-white text-black shadow-[0px_0px_0px_2px_#0591FF1A]",
    Disabled: "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed",
  },
  dark: {
    Default: "border-[#424242] bg-[#424242] text-white",
    Hover: "border-[#97BDF4] bg-[#424242] text-white",
    Focused: "border-[#7EADF2] bg-[#424242] border-[2px] text-white shadow-[0px_0px_0px_2px_#0591FF1A]",
    Disabled: "border-gray-600 bg-[#424242] text-gray-500 cursor-not-allowed",
  },
};

const sizeClasses = {
  Small: "text-sm px-2 py-1 rounded-md",
  Medium: "text-md px-4 py-2 rounded-md",
  Large: "text-lg px-6 py-3 rounded-lg",
};

const Input: React.FC<InputProps> = ({
  type = "text",
  placeholder = "",
  value,
  onChange,
  onKeyDown, // Destructure the onKeyDown prop
  state = "Default",
  size = "Medium",
  disabled = false,
  theme = "light",
  className = "",
  icon,
  iconPosition = "left", // Default icon position
}) => {
  const [inputState, setInputState] = useState<"Default" | "Hover" | "Focused">(state);

  const handleFocus = () => !disabled && setInputState("Focused");
  const handleBlur = () => !disabled && setInputState("Default");

  const handleMouseEnter = () => {
    if (!disabled && inputState !== "Focused") setInputState("Hover");
  };

  const handleMouseLeave = () => {
    if (!disabled && inputState !== "Focused") setInputState("Default");
  };

  return (
    <div className={clsx("relative w-full", className)}>
      {icon && iconPosition === "left" && (
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
          {icon}
        </span>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown} // Attach onKeyDown to the input element
        placeholder={placeholder}
        className={clsx(
          "transition border w-full outline-none", // Default padding for icon
          icon && iconPosition === "left" ? "pl-10" : "pr-10", // Adjust padding for icon
          disabled
            ? stateClasses[theme].Disabled
            : stateClasses[theme][inputState],
          sizeClasses[size] // Dynamic size class
        )}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        disabled={disabled}
      />
      {icon && iconPosition === "right" && (
        <span className="absolute right-3 top-1/2 transform -translate-y-1/2">
          {icon}
        </span>
      )}
    </div>
  );
};

export default Input;
