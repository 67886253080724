import React, { useState } from "react";
import { Link } from "react-router-dom";
import Typography from "./Typography";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FolderOutlined, SettingOutlined } from "@ant-design/icons";

const Sidebar: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="w-64 bg-[#1A1C4E] text-white h-screen p-4 flex flex-col gap-6">
      <Typography variant="LG" text="Nozma" className="text-[#46CDEA]" strong />
      <nav className="h-full flex flex-col justify-between">
        <div>
          <button
            onClick={toggleDropdown}
            className="flex items-center justify-between w-full p-2 rounded hover:bg-[#E7ECF3] hover:text-blue-primary"
          >
            <FolderOutlined />
            <Typography variant="LG" text="Marketing Automati..." />
            {isDropdownOpen ? <FiChevronUp /> : <FiChevronDown />}
          </button>
          {isDropdownOpen && (
            <div className="ml-4 mt-2 space-y-2">
              <Link
                to="/dashboard"
                className="block py-2 px-4 rounded hover:bg-[#E7ECF3] hover:text-blue-primary"
              >
                Project Info
              </Link>
              <Link
                to="/vendors"
                className="block py-2 px-4 rounded hover:bg-[#E7ECF3] hover:text-blue-primary"
              >
                Vendors
              </Link>
              <Link
                to="/evaluation"
                className="block py-2 px-4 rounded hover:bg-[#E7ECF3] hover:text-blue-primary"
              >
                Evaluation Scorecard
              </Link>
              <Link
                to="/tasks"
                className="block py-2 px-4 rounded hover:bg-[#E7ECF3] hover:text-blue-primary"
              >
                Tasks
              </Link>
            </div>
          )}
        </div>
        {/* Settings Link */}
        <Link
          to="/settings"
          className="block py-2 px-4 rounded hover:bg-[#E7ECF3] hover:text-blue-primary flex lfex-row gap-2"
        >
          <SettingOutlined />
          <Typography variant="LG" text="Settings" />
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;
