import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BuildTemplate from "./CriteriaRequirement";
import Typography from "style-guide/component/Typography";
import ProgressBar from "style-guide/component/Progressbar";
import Button from "style-guide/component/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  setCriteriaRequirements,
  setWeights,
} from "../../../redux/slice/templates";
import Loading from "style-guide/component/Loader";
import { ChipsData, WeightsData } from "../../../@types/templates";
import Menu from "style-guide/component/Menu";

const BuildTemplateFlow: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(2);
  const [criteriaWeight, setCriteriaWeights] = useState<WeightsData>({});
  const [chipsData, setChipsData] = useState<ChipsData>({});
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const queryParams = new URLSearchParams(location.search);
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  const initialComponent = (() => {
    const componentParam = queryParams.get("component");
    if (componentParam === "weightDone") return "weightDone";
    if (componentParam === "weight") return "weight";
    return "requirement";
  })();

  const [currentComponent, setCurrentComponent] = useState<
    "requirement" | "weight" | "weightDone"
  >(initialComponent);

  const handleNext = async () => {
    if (currentComponent === "requirement") {
      const response = await dispatch(setCriteriaRequirements(chipsData));
      if (response === 200) {
        navigate("/build_templates?component=weight");
        setCurrentComponent("weight");
      }
    } else if (currentComponent === "weight") {
      navigate("/build_templates?component=weightDone");
      setCurrentComponent("weightDone");
    } else if (currentComponent === "weightDone") {
      const resposne = await dispatch(setWeights(criteriaWeight));
      if (resposne === 200) {
        navigate("/upload_response");
      }
    }
  };

  const handleBack = () => {
    if (currentComponent === "requirement") {
      navigate("/project_overview?component=review");
      setCurrentStep(1);
    } else if (currentComponent === "weight") {
      setCurrentComponent("requirement");
      navigate("/build_templates?component=requirement");
    } else {
      setCurrentComponent("weight");
      navigate("/build_templates?component=weight");
    }
  };

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] py-8 px-16 flex flex-col gap-8">
      {/* Header Section */}
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <ProgressBar currentStep={currentStep} />

      <BuildTemplate
        currentComponent={currentComponent}
        chipsData={chipsData}
        setChipsData={setChipsData}
        setCriteriaWeights={setCriteriaWeights}
        criteriaWeight={criteriaWeight}
        setTotalWeight={setTotalWeight}
        totalWeight={totalWeight}
      />

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      )}

      {/* Footer Section */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex justify-between items-center bg-white p-4 border-t border-gray-300">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type={
            isLoading ||
            (currentComponent === "weightDone" && totalWeight !== 100)
              ? "Disabled"
              : "Primary"
          }
          size="Large"
          icon={false}
          buttonText={
            currentComponent === "requirement"
              ? "Next"
              : currentComponent === "weight"
                ? "Generate Weights"
                : "Next: Upload RFP Responses"
          }
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default BuildTemplateFlow;
