import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "./Alert";
import { setShowToast } from "../../redux/slice/application";
import { RootState } from "redux/store";

// Define the valid types for the Alert component
const validAlertTypes = [
  "success",
  "error",
  "warning",
  "info",
  "primary",
] as const;
type AlertType = (typeof validAlertTypes)[number];

const Toast: React.FC = () => {
  const dispatch = useDispatch();
  const { showToast, toastMessage, type } = useSelector(
    (state: RootState) => state.applicationData
  );

  // Use a default value if the type is not valid
  const alertType: AlertType = validAlertTypes.includes(type as AlertType)
    ? (type as AlertType)
    : "info";

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        dispatch(setShowToast({ show: false, toastMessage: "" }));
      }, 2000);

      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [showToast, dispatch]);

  if (!showToast) return null; // Don't render if showToast is false

  return (
    <div
      style={{
        position: "fixed",
        top: "30px",
        right: "30px",
        zIndex: 9999,
        // maxWidth: "300px",
      }}
    >
      <Alert
        icon
        type={alertType}
        title=""
        description={toastMessage}
        theme="light"
        dismissible
        onClose={() =>
          dispatch(setShowToast({ show: false, toastMessage: "" }))
        }
      />
    </div>
  );
};

export default Toast;
