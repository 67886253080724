import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
// import { sendInvite, setShowToast } from "redux/applicationSlice"; // Update the import path based on your setup
import Button from "style-guide/component/Button";
import Input from "style-guide/component/Input";
import Loading from "style-guide/component/Loader";
import Typography from "style-guide/component/Typography";

const InviteStakeholder = () => {
  const dispatch = useDispatch();
  const [userInvite, setUserInvite] = useState<string>("");
  const [emailList, setEmailList] = useState<string[]>([]);
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  function getRandomColor() {
    // Generate random HSL color with a light background
    const hue = Math.floor(Math.random() * 360); // Random hue value between 0 and 360
    const lightness = 70; // Light background color
    const bgColor = `hsl(${hue}, 60%, ${lightness}%)`;
    const textColor = `hsl(${hue}, 60%, ${lightness - 40}%)`; // Darker text color of same hue

    return { bgColor, textColor };
  }

  // const { userProfiledata } = useSelector((state: RootState) => state.promptdata);

  // const extractDomain = (email: string) => {
  //   return email.slice(email.lastIndexOf("@") + 1);
  // };

  const handleAddInvite = () => {
    // const userProfileDomain = extractDomain(userProfiledata.email);
    // const emailDomain = extractDomain(userInvite.trim());

    // if (userProfileDomain === emailDomain) {
    setEmailList((prevList) => [...prevList, userInvite.trim()]);
    setUserInvite("");
    // } else {
    //   dispatch(setShowToast({ show: true, toastMessage: "Email domains do not match." }));
    // }
  };

  const handleDeleteEmail = (index: number) => {
    setEmailList((prevList) => prevList.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (emailList.length > 0) {
      // dispatch(sendInvite({ emails: emailList.join(",") }));
    }
  }, [emailList, dispatch]);

  return (
    <>
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <>
          <div className="gap-2 flex flex-col justify-between p-4">
            <Typography
              text="Finally, invite stakeholders to collaborate on your evaluation."
              variant="Heading 3"
              className="text-black"
            />
            <Typography
              text="They can be assigned as scorers for your evaluation and will help approve the criteria, requirements, and scores in their assigned sections."
              variant="LG"
              className="text-black"
            />
          </div>

          <div className="flex gap-4 mt-2">
            <Input
              placeholder="Add emails, separated by commas"
              size="Medium"
              theme="light"
              className="w-full sm:w-[300px] md:w-[400px] lg:w-[650px]"
              value={userInvite}
              onChange={(e) => setUserInvite(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === " " && userInvite.trim()) {
                  handleAddInvite();
                }
              }}
            />
            <Button
              type={userInvite ? "Default" : "Disabled"}
              size="Medium"
              buttonText="Add"
              onClick={handleAddInvite}
            />
          </div>
          <div className="flex flex-col gap-2 sm:flex-row p-4">
            <div className="flex flex-wrap items-center gap-2">
              {emailList.map((email, index) => (
                <div
                  key={index}
                  className="flex items-center bg-gray-200 p-2 rounded-md"
                >
                  <span className="text-sm">{email}</span>
                  <CloseOutlined
                    className="ml-2 cursor-pointer text-gray-500"
                    onClick={() => handleDeleteEmail(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-4 p-4">
            <Typography
              text="Suggestions"
              variant="Base"
              className="text-[#000000BF]"
              strong
            />

            {["Business", "Finance", "Legal", "Infosec"].map((category) => (
              <div key={category} className="flex flex-col gap-2">
                <Typography
                  text={category}
                  variant="Base"
                  className="text-[#000000BF]"
                />

                <div className="flex flex-wrap gap-4">
                  {[...Array(3)].map((_, i) => {
                    const { bgColor, textColor } = getRandomColor();
                    return (
                      <div
                        key={i}
                        className="flex items-center border border-dashed border-blue-primary p-2 gap-2 rounded-md"
                      >
                        <PlusOutlined className="h-3 w-3" />
                        <div
                          className="w-6 h-6 rounded-full bg-blue-primary text-white flex justify-center items-center"
                          style={{ backgroundColor: bgColor, color: textColor }} // Apply colors dynamically
                        >
                          <Typography text="N" variant="SM" />
                        </div>
                        <Typography
                          text="Name Here"
                          variant="Base"
                          className="text-[#000000]"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default InviteStakeholder;
