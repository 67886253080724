import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import { api } from "api/axiosInterceptor";
// import { apiUrl } from "api/apiUrl";
import {
  decrementRequests,
  incrementRequests,
} from "./application";
import { IEvaluationState } from "../../@types/evaluation";

const initialState: IEvaluationState = {
  evalData: {},
};

const slice = createSlice({
  name: "evalutaion",
  initialState,
  reducers: {
    rfpEvaluation(state, action) {
      state.evalData = action.payload;
    },
  },
});

export const { rfpEvaluation } = slice.actions;

export default slice.reducer;

export function rfpResponseEvaluation(files: string[]) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = "/rfp-response-eval/";
      const response = await api.post(path, files);
      dispatch(rfpEvaluation(response.data));
      return response.status;
    } catch (error: any) {
      const status = error.response ? error.response.status : 500;
      // if (status !== 500 || status !== 502) {
      //   dispatch(
      //     setShowToast({
      //       show: true,
      //       type: "error",
      //       toastMessage: "Something went wrong!",
      //     })
      //   );
      // }
      return status;
    } finally {
      dispatch(decrementRequests());
    }
  };
}
