import React from "react";
import { CheckOutlined } from "@ant-design/icons";

interface ProgressBarProps {
  currentStep: number;
  totalSteps?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentStep,
  totalSteps = 4,
}) => {
  const steps = [
    "Project Overview",
    "Build Template",
    "Upload Responses",
    "Invite Stakeholders",
  ];

  return (
    <div className="w-full flex items-center">
      {steps.map((step, index) => (
        <div key={index} className="flex-1 flex items-center">
          <div
            className={`relative flex items-center justify-center w-8 h-8 rounded-full ${
              currentStep > index + 1
                ? "bg-[#E7ECF3] text-blue-primary" // Completed step
                : currentStep === index + 1
                ? "bg-blue-primary text-white" // Current step
                : "bg-[#0000000F] text-[#00000099]" // Upcoming steps
            }`}
          >
            {currentStep > index + 1 ? <CheckOutlined /> : index + 1}
          </div>
          <span
            className={`ml-2 text-sm ${
              currentStep >= index + 1 ? "text-black" : "text-gray-500"
            }`}
          >
            {step}
          </span>
          {index < totalSteps - 1 && (
            <div
              className={`flex-1 h-[1px] mx-2 ${
                currentStep > index + 1 ? "bg-blue-primary" : "bg-gray-300"
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
