import axios, {
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";

// Create an instance of Axios
export const api = axios.create({
  baseURL: "/api",
});

// Add a request interceptor
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Modify the response data here
    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 403) {
      // Clear the cookie token
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      // Redirect to login page
      window.location.replace("/");
    }

    // Handle other response errors here
    return Promise.reject(error);
  }
);
