import WebSocketProvider from "api/websocket";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ProtectedRouteProps {
  component: React.ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const navigate = useNavigate();
  const userAuth = document.cookie;

  useEffect(() => {
    if (!userAuth) {
      navigate("/");
    }
  }, [userAuth, navigate]);

  return (
    <WebSocketProvider>
      <Component />
    </WebSocketProvider>
  );
};

export default ProtectedRoute;
