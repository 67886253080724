import React from "react";

const Loading: React.FC = () => {
  return (
    <div className="flex justify-center items-center">
      {/* Default spinner loader */}
      <div
        className="w-10 h-10 border-4 border-t-blue-primary border-solid rounded-full animate-spin"
        role="status"
      />
    </div>
  );
};

export default Loading;
