import React, { useState } from "react";
import {
  IoIosInformationCircle,
  IoIosCheckmarkCircle,
  IoIosCloseCircle,
} from "react-icons/io";

type AlertProps = {
  type: "success" | "error" | "warning" | "info" | "primary";
  title: string;
  description?: string;
  onClose?: () => void;
  dismissible?: boolean;
  icon?: boolean;
  theme?: "light" | "dark";
};

const typeClasses = {
  light: {
    primary:
      "shadow-[0px_9px_28px_8px_rgba(0,0,0,0.05),0px_3px_6px_-4px_rgba(0,0,0,0.12),0px_6px_16px_0px_rgba(0,0,0,0.08)] p-4 rounded-md bg-white",
    success: "border-[#B7EB8F] border-[1px] bg-[#F6FFED] text-black",
    error: "border-[#FFCCC7] border-[1px] bg-[#FFF2F0] text-black",
    warning: "bg-yellow-100 border-[1px] text-black border-yellow-400",
    info: "bg-[#E7ECF3] text-black border-[#9FB3D1] border-[1px]",
  },
  dark: {
    primary:
      "shadow-[0px_9px_28px_8px_rgba(0,0,0,0.05),0px_3px_6px_-4px_rgba(0,0,0,0.12),0px_6px_16px_0px_rgba(0,0,0,0.08)] p-4 rounded-md bg-[#1F1F1F] text-white",
    success: "bg-[#162312] border-[1px] text-white border-[#274916]",
    error: "bg-[#2C1618] border-[1px] text-white border-[#5B2526]",
    warning: "bg-yellow-100 text-yellow-800 border-yellow-400",
    info: "bg-[#192230] text-white border-[#3F5679] border-[1px]",
  },
};

const getIcon = (type: string, theme: string) => {
  const iconColor = theme === "light" ? "#0F428D" : "#59A5FF";
  switch (type) {
    case "primary":
    case "info":
      return <IoIosInformationCircle color={iconColor} />;
    case "success":
      return (
        <IoIosCheckmarkCircle
          color={theme === "light" ? "#52C41A" : "#49AA19"}
        />
      );
    case "error":
      return (
        <IoIosCloseCircle color={theme === "light" ? "#FF4D4F" : "#DC4446"} />
      );
    case "warning":
      return <span style={{ color: iconColor }}>⚠️</span>;
    default:
      return null;
  }
};

const Alert: React.FC<AlertProps> = ({
  type = "info",
  title,
  description,
  onClose,
  dismissible = false,
  icon = false,
  theme = "light",
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) onClose();
  };

  if (!isVisible) return null;

  return (
    <div
      className={`w-[450px] flex items-center justify-center p-4 rounded-md ${
        typeClasses[theme][type]
      }`}
    >
      {icon && <span className="mr-2 text-xl flex justify-center">{getIcon(type, theme)}</span>}
      <div className="flex-1">
        {title && <span className="text-base font-medium mb-2">{title}</span>}
        {description && <p className="text-sm">{description}</p>}
      </div>
      {dismissible && (
        <button
          className="ml-4 text-lg focus:outline-none"
          onClick={handleClose}
        >
          &times;
        </button>
      )}
    </div>
  );
};

export default Alert;
