import { configureStore } from "@reduxjs/toolkit";
import applicationReducer from "./slice/application";
import overviewReducer from "./slice/overview";
import templatesReducer from "./slice/templates";
import evaluationReducer from "./slice/evaluation";

export const store = configureStore({
  reducer: {
    applicationData: applicationReducer,
    overviewData: overviewReducer,
    templatesData: templatesReducer,
    evaluationData: evaluationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
