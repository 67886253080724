// import Headings from "./Headings";
import { useState } from "react";
import { useTheme } from "theme/ThemeContext";
import Button from "./component/Button";
import Input from "./component/Input";
import TextArea from "./component/Textarea";
import Alert from "./component/Alert";
import { IoMdSearch } from "react-icons/io";

const StyleGuide = () => {
  const { theme, setTheme } = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");

  return (
    <div className={`app ${theme}`}>
      <div
        className={`w-[100%] p-4 flex flex-col gap-4 ${theme === "dark" ? "bg-[#141414]" : "bg-white"}`}
      >
        <div className="mb-4">
          <button
            onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
            className={`px-4 py-2 rounded-md ${theme === "dark" ? "bg-gray-300" : "bg-gray-800 text-white"}`}
          >
            Toggle Theme
          </button>
        </div>
        {/* <Headings /> */}

        {/* buttons */}
        <div className="flex flex-row gap-4">
          <Button
            type="Primary"
            size="Small"
            icon={false}
            buttonText="Primary Button"
            theme={theme}
          />

          <Button
            type="Primary"
            size="Small"
            icon
            iconComponent={<IoMdSearch />}
            theme={theme}
          />
          <Button
            type="Default"
            size="Small"
            icon={false}
            buttonText="Default Button"
            theme={theme}
          />
          <Button
            type="Default"
            size="Small"
            icon
            iconComponent={<IoMdSearch />}
            theme={theme}
          />
          <Button
            type="Dashed"
            size="Small"
            icon={false}
            buttonText="Dashed Button"
            theme={theme}
          />
          <Button
            type="Dashed"
            size="Small"
            iconComponent={<IoMdSearch />}
            icon
            theme={theme}
          />
        </div>
        <div className="flex flex-row gap-4">
          <Button
            type="Primary"
            size="Medium"
            icon={false}
            buttonText="Primary Button"
            theme={theme}
          />
          <Button
            type="Primary"
            size="Medium"
            icon
            iconComponent={<IoMdSearch />}
            theme={theme}
          />
          <Button
            type="Default"
            size="Medium"
            icon={false}
            buttonText="Default Button"
            theme={theme}
          />
          <Button
            type="Default"
            size="Medium"
            iconComponent={<IoMdSearch />}
            icon
            theme={theme}
          />
          <Button
            type="Dashed"
            size="Medium"
            icon={false}
            buttonText="Dashed Button"
            theme={theme}
          />
          <Button
            type="Dashed"
            size="Medium"
            icon
            iconComponent={<IoMdSearch />}
            theme={theme}
          />
        </div>
        <div className="flex flex-row gap-4">
          <Button
            type="Primary"
            size="Large"
            icon={false}
            theme={theme}
            buttonText="Primary Button"
          />
          <Button
            type="Primary"
            size="Large"
            icon
            iconComponent={<IoMdSearch />}
            theme={theme}
          />
          <Button
            type="Default"
            size="Large"
            icon={false}
            buttonText="Default Button"
            theme={theme}
          />
          <Button
            type="Default"
            size="Large"
            icon
            iconComponent={<IoMdSearch />}
            theme={theme}
          />
          <Button
            type="Dashed"
            size="Large"
            icon={false}
            buttonText="Dashed Button"
            theme={theme}
          />
          <Button
            type="Dashed"
            size="Large"
            icon
            iconComponent={<IoMdSearch />}
            theme={theme}
          />
        </div>

        {/* input */}
        <div className="flex flex-row gap-4 w-[50%]">
          <Input
            placeholder="Default Input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            state="Default"
            size="Small"
            theme={theme}
          />
          <Input
            placeholder="Default Input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            state="Hover"
            size="Small"
            theme={theme}
          />
          <Input
            placeholder="Default Input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            state="Focused"
            size="Small"
            theme={theme}
          />
          <Input
            type="text"
            placeholder="Disabled Input"
            size="Small"
            value=""
            disabled
            theme={theme}
          />
        </div>
        <div className="flex flex-row gap-4 w-[50%]">
          <Input
            placeholder="Default Input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            state="Default"
            size="Medium"
            theme={theme}
          />
          <Input
            placeholder="Default Input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            state="Hover"
            size="Medium"
            theme={theme}
          />
          <Input
            placeholder="Default Input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            state="Focused"
            size="Medium"
            theme={theme}
          />
          <Input
            type="text"
            placeholder="Disabled Input"
            size="Medium"
            value=""
            disabled
            theme={theme}
          />
        </div>
        <div className="flex flex-row gap-4 w-[50%]">
          <Input
            placeholder="Default Input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            state="Default"
            size="Large"
            theme={theme}
          />
          <Input
            placeholder="Default Input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            state="Hover"
            size="Large"
            theme={theme}
          />
          <Input
            placeholder="Default Input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            state="Focused"
            size="Large"
            theme={theme}
          />
          <Input
            type="text"
            placeholder="Disabled Input"
            size="Large"
            value=""
            disabled
            theme={theme}
          />
        </div>

        {/* textarea */}
        <div className="flex flex-row gap-4 w-[50%]">
          <TextArea
            placeholder="TextArea"
            size="Small"
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            disabled={false}
            theme={theme}
          />
          <TextArea
            placeholder="TextArea"
            size="Small"
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            disabled={false}
            state="Hover"
            theme={theme}
          />
          <TextArea
            placeholder="TextArea"
            size="Small"
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            disabled={false}
            state="Focused"
            theme={theme}
          />
          <TextArea
            placeholder="TextArea"
            size="Small"
            value=""
            disabled
            theme={theme}
          />
        </div>

        {/* alert */}
        <div className="flex flex-row gap-4">
          <div className="flex flex-col">
            <Alert
              type="info"
              title="This is a success alert!"
              dismissible
              theme={theme}
            />
            <Alert
              type="info"
              title="Title"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus interdum hendrerit ex vitae sodales. Donec id leo ipsum. Phasellus volutpat aliquet mauris."
              dismissible
              icon
              theme={theme}
            />
            <Alert
              type="primary"
              title="This is notification alert!"
              dismissible
              theme={theme}
            />
          </div>
          <div className="flex flex-col">
            <Alert
              type="success"
              title="This is a success alert!"
              dismissible
              theme={theme}
            />
            <Alert
              type="success"
              title="Title"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus interdum hendrerit ex vitae sodales. Donec id leo ipsum. Phasellus volutpat aliquet mauris."
              dismissible
              icon
              theme={theme}
            />
          </div>
          <div className="flex flex-col">
            <Alert
              type="error"
              title="This is a error alert!"
              dismissible
              theme={theme}
            />
            <Alert
              type="error"
              title="Title"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus interdum hendrerit ex vitae sodales. Donec id leo ipsum. Phasellus volutpat aliquet mauris."
              dismissible
              icon
              theme={theme}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default StyleGuide;
