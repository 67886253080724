import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApplicationState, LoginCredentials } from "../../@types/application";
import { AppDispatch } from "redux/store";
import { api } from "api/axiosInterceptor";
import { apiUrl } from "api/apiUrl";

const initialState: IApplicationState = {
  isLoading: false,
  successLogin: false,
  showToast: false,
  toastMessage: "",
  type: "",
  pendingRequests: 0,
};

const slice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    loginsuccess(state, action: PayloadAction<boolean>) {
      state.successLogin = action.payload;
    },
    setShowToast(state, action) {
      state.showToast = action.payload.show;
      state.toastMessage = action.payload.toastMessage;
      state.type = action.payload.type;
    },
    incrementRequests(state) {
      state.pendingRequests += 1;
      state.isLoading = true;
    },
    decrementRequests(state) {
      state.pendingRequests = Math.max(0, state.pendingRequests - 1);
      if (state.pendingRequests === 0) {
        state.isLoading = false;
      }
    },
  },
});

export const { setIsLoading, loginsuccess, setShowToast, incrementRequests, decrementRequests } = slice.actions;

export default slice.reducer;

export function login({ login, password }: LoginCredentials) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl.login;
      const response = await api.post(path, { login, password });
      dispatch(loginsuccess(response.data));
      dispatch(
        setShowToast({
          show: true,
          type: "success",
          toastMessage: "Logged in Successfully",
        })
      );
    } catch (error) {
      dispatch(loginsuccess(false));
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Login Failed",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}
