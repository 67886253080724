import {
  BarChartOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  FileTextOutlined,
  PlusOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";
import { BsArrowsCollapse } from "react-icons/bs";
import { MdExpand } from "react-icons/md";
import Input from "style-guide/component/Input";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { IFile } from "components/intake/uploadResponse/UploadDocument";
import { getFileList } from "../../redux/slice/overview";
import { rfpResponseEvaluation } from "../../redux/slice/evaluation";
import { getCriteriaRquirements } from "../../redux/slice/templates";
import Loading from "style-guide/component/Loader";

interface Product {
  name: string;
  answers: number;
  description: string;
  is_requirements: boolean;
  pages_sections: string;
}

interface Criterion {
  name: string;
  requirements: any[];
  status: string;
  products: Product[];
  totalAnswers: number;
  actualAnswers: number;
}

interface Category {
  totalAnswers: number;
  actualAnswers: number;
  category: string;
  criteria: Criterion[];
}

const EvaluationScorecard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [expandedCriteria, setExpandedCriteria] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [totalAnswers, setTotalAnswers] = useState(0);
  const [actualAnswers, setActualAnswers] = useState(0);
  const [evalLoading, setEvalLoading] = useState<boolean>(false);

  const { listOfFiles } = useSelector((state: RootState) => state.overviewData);
  const { evalData } = useSelector((state: RootState) => state.evaluationData);
  const { criteria } = useSelector((state: RootState) => state.templatesData);
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  useEffect(() => {
    const fetchCriteriaRequirement = async () => {
      const response = await dispatch(getCriteriaRquirements());

      if (response === 500 || response === 502) {
        dispatch(getCriteriaRquirements());
      }
    };

    const fetchFile = async () => {
      const response = await dispatch(getFileList("RFP_Response"));

      if (response === 200) {
        fetchCriteriaRequirement();
        setEvalLoading(true);
      }
    };

    fetchFile();
  }, []);

  useEffect(() => {
    if (listOfFiles?.files?.length > 0 && evalLoading) {
      const updatedFiles: IFile[] = listOfFiles.files.map((apiFile) => {
        if (apiFile?.rfp_responses?.is_rfp_response) {
          return { ...apiFile, status: "accepted" };
        } else {
          return { ...apiFile, status: "error" };
        }
      });
      const validFiles = updatedFiles
        .filter(
          (file) =>
            file.status === "accepted" && file.rfp_responses?.company_name
        )
        .map((file) => file.rfp_responses.company_name as string);
      const fetchResponseEval = async () => {
        const response = await dispatch(rfpResponseEvaluation(validFiles));

        if (response === 500 || response === 502 || response === 400) {
          dispatch(rfpResponseEvaluation(validFiles));
        }
      };

      fetchResponseEval();
    }
  }, [evalLoading]);

  const formatEvaluationData = (responseData: any): Category[] => {
    const companies = Object.keys(responseData);
    const evaluationData: Category[] = [];
    let categoryTotalAnswers = 0;
    let categoryActualAnswers = 0;
    for (const categoryName in responseData[companies[0]]) {
      if (Object.hasOwn(responseData[companies[0]], categoryName)) {
        const categoryData: Category = {
          category: categoryName,
          criteria: [],
          totalAnswers: 0,
          actualAnswers: 0,
        };

        for (const criterionName in responseData[companies[0]][categoryName]) {
          if (
            Object.hasOwn(
              responseData[companies[0]][categoryName],
              criterionName
            )
          ) {
            const criterion: Criterion = {
              name: criterionName,
              requirements:
                criteria[categoryName]?.[criterionName]?.requirements || [],
              status: "Responses completed",
              products: [],
              totalAnswers: 0,
              actualAnswers: 0,
            };

            let allCompaniesAnswered = true;

            companies.forEach((companyName) => {
              const companyData =
                responseData[companyName][categoryName][criterionName];
              const hasDescription = companyData?.response_summary;

              if (!hasDescription) {
                allCompaniesAnswered = false;
              } else {
                criterion.actualAnswers += 1;
                categoryData.actualAnswers += 1;
              }
              criterion.totalAnswers += 1;
              categoryData.totalAnswers += 1;

              criterion.products.push({
                name: companyName,
                answers: hasDescription ? 1 : 0,
                description: hasDescription || "",
                is_requirements:
                  companyData?.is_requirements_satisfied || false,
                pages_sections: companyData?.pages || "",
              });
            });

            // // Update status based on whether all companies answered
            if (allCompaniesAnswered) {
              criterion.status = "Responses completed";
            } else {
              criterion.status = "Responses needed";
            }

            categoryData.criteria.push(criterion);
          }
        }

        categoryTotalAnswers += categoryData.totalAnswers;
        categoryActualAnswers += categoryData.actualAnswers;
        evaluationData.push(categoryData);
      }
    }

    setTotalAnswers(categoryTotalAnswers);
    setActualAnswers(categoryActualAnswers);

    return evaluationData;
  };

  const evaluationData = useMemo(
    () => formatEvaluationData(evalData),
    [evalData]
  );
  console.log("🚀 ~ evaluationData:", evaluationData);

  useEffect(() => {
    const company = Object.keys(evalData);
    setCompanies(company);
  }, [evalData]);
  const toggleDropdown = (criteriaName: string) => {
    setExpandedCriteria((prevExpanded) =>
      prevExpanded.includes(criteriaName)
        ? prevExpanded.filter((name) => name !== criteriaName)
        : [...prevExpanded, criteriaName]
    );
  };
  const expandAll = () => {
    const allCriteriaNames = evaluationData.flatMap((category) =>
      category.criteria.map((criterion) => criterion.name)
    );
    setExpandedCriteria(allCriteriaNames);
  };
  const collapseAll = () => {
    setExpandedCriteria([]);
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      )}
      <div className="bg-white p-8 flex flex-col gap-6">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex flex-col md:flex-row gap-2 md:items-center md:gap-4 items-start">
            <Typography variant="Heading 2" text="Evaluation Scorecard" />
            <div className="flex flex-row text-[#135200] items-center gap-1 bg-[#F6FFED]">
              <FileTextOutlined className="h-3 w-3" />
              <Typography variant="SM" text="Evaluating" />
            </div>
          </div>
          <div className="w-full md:w-auto flex flex-row md:flex-row gap-2 items-center justify-center">
            <Button
              type="Default"
              buttonText="Share"
              icon
              iconComponent={<UsergroupAddOutlined />}
            />
            <Button
              type="Disabled"
              buttonText="Create Report"
              icon
              iconComponent={<BarChartOutlined />}
            />
          </div>
        </div>
        {/* Summary Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="p-4 bg-white shadow-md rounded-lg">
            <Typography
              text="Criteria Answered"
              variant="Base"
              className="text-[#00000099]"
            />
            <Typography
              text={`${actualAnswers}/${totalAnswers}`}
              variant="Heading 3"
            />
          </div>
          <div className="p-4 bg-white shadow-md rounded-lg">
            <Typography
              text="Criteria Scored"
              variant="Base"
              className="text-[#00000099]"
            />
            <Typography text="0/15" variant="Heading 3" />
          </div>
          <div className="p-4 bg-white shadow-md rounded-lg">
            <Typography
              text="Criteria Approved"
              variant="Base"
              className="text-[#00000099]"
            />
            <Typography text="0/15" variant="Heading 3" />
          </div>
        </div>
        {/* Search and Action Buttons */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <Input
            placeholder="Search"
            state="Default"
            size="Small"
            iconPosition="right"
            icon={<SearchOutlined />}
            className="w-full md:w-1/3"
          />
          <div className="flex flex-row gap-2">
            <Button
              icon
              iconComponent={<BsArrowsCollapse />}
              onClick={collapseAll}
            />
            <Button icon iconComponent={<MdExpand />} onClick={expandAll} />
            <Button type="Default" buttonText="Import Notes" />
            <Button type="Disabled" buttonText="Generate Scores" />
          </div>
        </div>
        {/* Table Section */}
        {evaluationData.map((category) => (
          <div
            key={category.category}
            className="bg-white shadow rounded-lg overflow-hidden mt-6"
          >
            <Typography
              variant="Heading 4"
              text={category.category}
              className="p-4"
            />
            <div className="overflow-x-auto">
              <table className="w-full text-left">
                <thead>
                  <tr className="h-[54px]">
                    <th className="w-[40px] p-4" />
                    <th className="w-[250px] p-4">
                      <Typography variant="Base" text="Criteria" strong />
                    </th>
                    <th className="w-[350px] p-4 hidden md:table-cell">
                      <Typography variant="Base" text="Requirements" strong />
                    </th>
                    <th className="w-[190px] p-4">
                      <Typography variant="Base" text="Status" strong />
                    </th>
                    {companies?.map((companyName) => (
                      <th
                        className="w-[170px] p-4 hidden md:table-cell"
                        key={companyName}
                      >
                        <Typography variant="Base" text={companyName} strong />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {category.criteria.map((criterion, index) => (
                    <>
                      <tr key={index} className="border-t border-b">
                        <td className="p-4 text-[#00000099]">
                          {expandedCriteria.includes(criterion.name) ? (
                            <CaretDownOutlined
                              onClick={() => toggleDropdown(criterion.name)}
                            />
                          ) : (
                            <CaretRightOutlined
                              onClick={() => toggleDropdown(criterion.name)}
                            />
                          )}
                        </td>
                        <td className="p-4 border-r border-[#0000000F]">
                          <Typography
                            variant="Base"
                            text={criterion.name}
                            strong
                          />
                        </td>

                        <td className="p-4 border-r border-[#0000000F] hidden md:table-cell">
                          <div className="flex flex-wrap gap-2">
                            {criterion.requirements.map((req, idx) => (
                              <div
                                key={idx}
                                className="bg-[#0000000F] rounded-md px-2 py-1 cursor-pointer"
                              >
                                <Typography variant="SM" text={req} />
                              </div>
                            ))}
                          </div>
                        </td>

                        <td className="p-4 border-r border-[#0000000F]">
                          <Typography
                            text={criterion.status}
                            variant="SM"
                            className={`px-3  w-fit py-1 rounded-lg ${criterion.status === "Responses needed" ? "bg-[#FFFBE6] text-[#874D00]" : "bg-[#F9F0FF] text-[#22075E]"}`}
                          />
                        </td>
                        {criterion.products.map((product, index) => (
                          <td
                            key={index}
                            className="p-4 border-r border-[#0000000F] hidden md:table-cell"
                          >
                            {product.is_requirements ? (
                              <CheckCircleFilled className="text-[#52C41A]" />
                            ) : (
                              <CloseCircleFilled className="text-[red]" />
                            )}
                          </td>
                        ))}
                      </tr>
                      {expandedCriteria.includes(criterion.name) && (
                        <tr className="bg-gray-50">
                          <td colSpan={7} className="px-4 pt-4">
                            <div className="flex flex-col gap-4">
                              {criterion.products.map((product, index) => (
                                <div key={index} className="border-b p-2">
                                  <div className="flex flex-row items-center">
                                    <Typography
                                      variant="Base"
                                      text={product.name}
                                      strong
                                      className="pr-2"
                                    />
                                    <b>.</b>
                                    <Typography
                                      variant="Base"
                                      text={`${product.answers} answer`}
                                      className="text-[#00000099] px-2"
                                    />
                                    <b>.</b>
                                    <Button
                                      type="Text"
                                      buttonText="Add an answer"
                                      size="Small"
                                      icon
                                      iconComponent={<PlusOutlined />}
                                      className="p-0"
                                    />
                                  </div>
                                  <Typography
                                    variant="Base"
                                    text={product.description}
                                  />
                                  <Typography
                                    variant="SM"
                                    text={`[Pages: ${product.pages_sections}]`}
                                  />
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EvaluationScorecard;
