import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "style-guide/component/Sidebar";
import { MenuOutlined } from "@ant-design/icons";

const Layout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleContentClick = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <div className="flex h-screen relative">
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-800 z-20 transform transition-transform duration-300 md:translate-x-0 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <Sidebar />
      </div>

      <button
        onClick={toggleSidebar}
        className="md:hidden absolute top-4 left-4 z-30 p-2 text-black rounded-full"
      >
        <MenuOutlined />
      </button>

      <main
        className={`flex-1 transition-all duration-300 ${
          isSidebarOpen ? "ml-64" : ""
        } md:ml-64`}
        onClick={handleContentClick}
      >
        <Outlet />
      </main>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-30 md:hidden z-10"
          onClick={toggleSidebar}
        />
      )}
    </div>
  );
};

export default Layout;
