export const textStyles = [
  { name: "Base", fontSize: "14px", lineHeight: "22px" },
  { name: "SM", fontSize: "12px", lineHeight: "20px" },
  { name: "LG", fontSize: "16px", lineHeight: "24px" },
  { name: "XL", fontSize: "18px", lineHeight: "24px" },
];

export const headings = [
  { name: "Heading 1", fontSize: "38px", lineHeight: "46px" },
  { name: "Heading 2", fontSize: "30px", lineHeight: "38px" },
  { name: "Heading 3", fontSize: "24px", lineHeight: "32px" },
  { name: "Heading 4", fontSize: "20px", lineHeight: "28px" },
  { name: "Heading 5", fontSize: "16px", lineHeight: "24px" },
];

export const allStyles = [...textStyles, ...headings];

export const variants = [
  { label: "Normal", style: {} },
  { label: "Strong", style: { fontWeight: "bold" } },
  { label: "Underline", style: { textDecoration: "underline" } },
  { label: "Delete", style: { textDecoration: "line-through" } },
  { label: "Italic", style: { fontStyle: "italic" } },
];
