import React, { useState, ChangeEvent, DragEvent, useEffect } from "react";
import Typography from "style-guide/component/Typography";
import {
  InboxOutlined,
  CloseOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";

import { setShowToast } from "../../../redux/slice/application";
import {
  uploadFile,
  deleteFile,
  downloadUploadedFile,
  getFileList,
} from "../../../redux/slice/overview";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useWebSocket } from "api/websocket";
import { Spin } from "antd";
import Button from "style-guide/component/Button";

export interface IFile {
  file_id: string;
  file_type: string;
  display_name: string;
  content_type: string;
  file_name: string;
  rfp_responses: {
    company_name: string;
    is_rfp_response: boolean;
  };
  status: "uploaded" | "pending" | "accepted" | "error";
}

interface IUploadComponent {
  setAllFilesValid: (isValid: boolean) => void;
}

const UploadDocument: React.FC<IUploadComponent> = ({ setAllFilesValid }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { message } = useWebSocket();
  const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
  const { listOfFiles } = useSelector((state: RootState) => state.overviewData);
  const [popupFileId, setPopupFileId] = useState<string | null>(null); // Tracks which popup is open

  useEffect(() => {
    dispatch(getFileList("RFP_Response"));
  }, [dispatch]);

  useEffect(() => {
    if (listOfFiles?.files) {
      const updatedFiles: IFile[] = listOfFiles.files.map((apiFile) => {
        if (apiFile?.rfp_responses?.is_rfp_response) {
          return { ...apiFile, status: "accepted" };
        } else {
          return { ...apiFile, status: "error" };
        }
      });

      setSelectedFiles((prevFiles) => [
        ...updatedFiles,
        ...prevFiles.filter((file) => file.status === "pending"),
      ]);

      const allValid =
        updatedFiles.length > 0 &&
        updatedFiles.every((file) => file.status === "accepted");
      setAllFilesValid(allValid);
    }
  }, [listOfFiles]);

  // rfp_resp.pdf Eval process completed True

  useEffect(() => {
    if (message && typeof message === "string") {
      const fileName = message.split(" ")[0];

      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) => {
          if (file.display_name === fileName) {
            if (message.includes("Eval process started")) {
              // Check if the current status is not yet "uploaded," then set it to "uploaded"
              return file.status === "uploaded"
                ? { ...file, status: "pending" }
                : { ...file, status: "uploaded" };
            } else if (message.includes("Eval process completed True")) {
              return { ...file, status: "accepted" };
            } else if (message.includes("Eval process completed False")) {
              return { ...file, status: "error" };
            }
          }
          return file;
        })
      );
    }
  }, [message]);

  const sanitizeFileName = (fileName: string) =>
    fileName.replace(/[^a-zA-Z0-9.\s_-]/g, "");

  const validateFile = (file: File) => {
    const allowedExtensions = ["pdf", "docx", "csv", "xlsx", "ppt"];
    const maxFileSize = 10 * 1024 * 1024;
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Only .pdf and .docx files are allowed.",
        })
      );
      return false;
    }

    if (file.size > maxFileSize) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "File size must not exceed 2.5MB.",
        })
      );
      return false;
    }

    return true;
  };

  const handleSelectedFiles = async (files: File[]) => {
    const sanitizedFiles = files.map((file) => {
      const sanitizedFileName = sanitizeFileName(file.name);
      return new File([file], sanitizedFileName, { type: file.type });
    });

    const validFiles = sanitizedFiles.filter(validateFile);

    const newSelectedFiles: IFile[] = validFiles.map((file) => ({
      file_id: `${Date.now()}-${Math.random()}`,
      display_name: file.name,
      content_type: file.type,
      file_name: file.name,
      rfp_responses: { company_name: "", is_rfp_response: false },
      file_type: "RFP_Response",
      status: "pending",
    }));

    setSelectedFiles((prev) => [
      ...prev.filter(
        (file) =>
          !newSelectedFiles.some((newFile) => newFile.file_id === file.file_id)
      ),
      ...newSelectedFiles,
    ]);

    const result = await dispatch(uploadFile("RFP_Response", validFiles));
    if (result?.status === 200 || result?.status === 400) {
      dispatch(getFileList("RFP_Response"));
      dispatch(
        setShowToast({
          show: true,
          type: "success",
          toastMessage: "Files uploaded successfully!",
        })
      );
      setSelectedFiles((prev) =>
        prev.map((file) => {
          const matchingNewFile = newSelectedFiles.find(
            (newFile) => newFile.file_id === file.file_id
          );
          if (matchingNewFile) {
            return {
              ...file,
              status: result.status === 200 ? "accepted" : "error",
            };
          }
          return file;
        })
      );
    } else {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Failed to upload files.",
        })
      );

      setSelectedFiles((prev) =>
        prev.map((file) =>
          newSelectedFiles.some((newFile) => newFile.file_id === file.file_id)
            ? { ...file, status: "error" }
            : file
        )
      );
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const files = Array.from(event.target.files);
    handleSelectedFiles(files);
  };

  const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleSelectedFiles(files);
  };

  const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const handleDelete = (fileId: string) => {
    setSelectedFiles((prev) => prev.filter((file) => file.file_id !== fileId));
    dispatch(deleteFile(fileId, "RFP_Response"));
    setPopupFileId(null);
  };

  const handleCancelDelete = () => {
    setPopupFileId(null);
  };

  const handleDownload = async (fileId: string) => {
    dispatch(downloadUploadedFile(fileId));
  };

  return (
    <>
      <div className="h-[fit-content] gap-2 flex flex-col justify-between p-4">
        <Typography
          text="Upload your RFP response documents from all the vendors."
          variant="Heading 3"
          className="text-black"
        />
        <Typography
          text="We will parse these files and populate in the evaluation template. "
          variant="LG"
          className="text-black"
        />
      </div>

      <label
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="h-[144px] w-full border-[1px] border-[#D9D9D9] border-dashed rounded-lg flex flex-col justify-center items-center cursor-pointer"
      >
        <InboxOutlined style={{ fontSize: "40px", color: "#0F428D" }} />
        <Typography
          text={`File Formats: PDF, Documents (DOC/DOCX), Spreadsheets (XLS/XLSX), Presentations (PPT/PPTX) \n Size: 10MB Max`}
          variant="LG"
          className="text-black text-center"
        />
        <input
          id="file-upload"
          multiple
          type="file"
          onChange={handleFileChange}
          className="hidden"
        />
      </label>

      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-2 items-center">
          <Typography
            variant="Heading 5"
            text={`Documents uploaded (${selectedFiles.length})`}
          />
          <Typography
            variant="SM"
            text={message !== null ? `${message}...` : ""}
            className="text-gray-500"
          />
        </div>
        {selectedFiles.length > 0 ? (
          selectedFiles.map((file) => (
            <>
              <div
                key={file.file_id}
                className="flex flex-row gap-2 items-center"
              >
                <div className="w-[380px] border-[1px] border-[#D9D9D9] flex flex-row items-center justify-between p-2 rounded-lg">
                  <div
                    className="flex flex-row items-center gap-3 cursor-pointer"
                    onClick={() => handleDownload(file.file_id)}
                  >
                    {file.status === "uploaded" ? (
                      <InfoCircleFilled className="text-[orange]" />
                    ) : file.status === "pending" ? (
                      <Spin size="small" />
                    ) : file.status === "accepted" ? (
                      <CheckCircleFilled className="text-[green]" />
                    ) : file.status === "error" ? (
                      <CloseCircleFilled className="text-[red]" />
                    ) : null}

                    <Typography
                      text={file.display_name}
                      variant="LG"
                      className="text-[#414141]"
                    />
                  </div>
                  <CloseOutlined
                    className="cursor-pointer w-3 h-3"
                    onClick={() => setPopupFileId(file.file_id)}
                  />
                </div>
                {(file.status === "accepted" || file.status === "error") &&
                  listOfFiles?.files?.length > 0 && (
                    <Typography
                      variant="SM"
                      text={
                        file?.rfp_responses?.is_rfp_response
                          ? `Uploaded RFP response from  ${file?.rfp_responses?.company_name}`
                          : "Uploaded file does not match the RFP response requirements."
                      }
                    />
                  )}
              </div>
              {popupFileId && (
                <div className="w-[400px] absolute bg-white border border-gray-300 shadow-md p-4 rounded-lg z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-10 p-4">
                  <Typography
                    text="Are you sure you want to delete this file?"
                    variant="LG"
                  />
                  <div className="flex flex-row gap-2 justify-end">
                    <Button
                      type="Default"
                      buttonText="Cancel"
                      onClick={handleCancelDelete}
                    />
                    <Button
                      type="Primary"
                      buttonText="Delete"
                      onClick={() => handleDelete(file.file_id)}
                    />
                  </div>
                </div>
              )}
            </>
          ))
        ) : (
          <Typography text="No documents uploaded." variant="SM" />
        )}
      </div>
    </>
  );
};

export default UploadDocument;
