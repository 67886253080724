import React from "react";
import clsx from "clsx";

type ButtonProps = {
  type?: "Primary" | "Default" | "Dashed" | "Text" | "Link" | "Disabled";
  size?: "Small" | "Medium" | "Large";
  icon?: boolean;
  iconComponent?: React.ReactNode;
  buttonText?: string;
  onClick?: () => void;
  theme?: "light" | "dark";
  className?: string;
};

const typeClasses = {
  light: {
    Primary: "bg-[#0F428D] text-white",
    Default: "bg-white border border-[#0F428D] text-[#0F428D]",
    Dashed:
      "border-dashed bg-white border border-[#0F428D] text-[#0F428D]",
    Text: "bg-transparent text-blue-primary",
    Link: "text-blue-primary underline",
    Disabled: "border-[1px] border-[#D9D9D9] text-[#00000073] cursor-not-allowed",
  },
  dark: {
    Primary: "bg-[#7EADF2] text-white",
    Default: "border border-[#7EADF2] text-[#7EADF2]",
    Dashed: "border-dashed border border-[#7EADF2] text-[#7EADF2]",
    Text: "bg-transparent text-blue-400",
    Link: "text-blue-400 underline",
    Disabled: "border-[1px] border-[#D9D9D9] text-[#00000073] cursor-not-allowed",
  },
};

const sizeClasses = {
  Small: "text-sm px-2 py-1 rounded-md",
  Medium: "text-md px-4 py-1 rounded-lg",
  Large: "text-lg px-6 py-1 rounded-lg",
};

const Button: React.FC<ButtonProps> = ({
  type = "Default",
  size = "Medium",
  icon = false,
  iconComponent,
  buttonText,
  onClick,
  theme = "light",
  className = "",
}) => {
  const isDisabled = type === "Disabled";

  return (
    <button
      className={clsx(
        "flex items-center justify-center space-x-2 transition",
        typeClasses[theme][type],
        sizeClasses[size],
        className
      )}
      onClick={!isDisabled ? onClick : undefined} // Prevent onClick if disabled
      disabled={isDisabled} // Set disabled attribute if type is Disabled
    >
      {icon && iconComponent && <span>{iconComponent}</span>}
      {buttonText && <span>{buttonText}</span>}
    </button>
  );
};

export default Button;
