import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadRFPResponse from "./UploadDocument";
import Typography from "style-guide/component/Typography";
import ProgressBar from "style-guide/component/Progressbar";
import Button from "style-guide/component/Button";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import Loading from "style-guide/component/Loader";
import Menu from "style-guide/component/Menu";

const UploadResponse: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(3);
  const [allFilesValid, setAllFilesValid] = useState<boolean>(false);

  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  const handleNext = async () => {
    navigate("/invite");
  };

  const handleBack = () => {
    setCurrentStep(2);
    navigate("/build_templates?component=weightDone");
  };

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] py-8 px-16 flex flex-col gap-8">
      {/* Header Section */}
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <ProgressBar currentStep={currentStep} />
      <UploadRFPResponse setAllFilesValid={setAllFilesValid} />

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center">
          <Loading />
        </div>
      )}

      {/* Footer Section */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex justify-between items-center bg-white p-4 border-t border-gray-300">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type={isLoading || !allFilesValid ? "Disabled" : "Primary"}
          size="Large"
          icon={false}
          buttonText="Next"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default UploadResponse;
